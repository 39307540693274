<template>
  <div class="quantity">
    <button class="quantity__btn quantity__btn--minus" type="button" @click="minus" :class="isColorChange">
      <svg width="14" height="3" class="icon-svg"><use xlink:href="@/assets/img/sprite.svg#minus"></use></svg>
    </button>
    <input :value="inputVal" class="quantity__input" type="text" />
    <button class="quantity__btn quantity__btn--plus" type="button" @click="plus" :class="isColorChange">
      <svg width="14" height="14" class="icon-svg"><use xlink:href="@/assets/img/sprite.svg#plus"></use></svg>
    </button>
  </div>
</template>
<script>
import { computed } from 'vue'
import { ref } from '@vue/runtime-core'
export default {
  name: 'Quantity',
  props: {
    value: {
      type: Number,
      default: 1
    }
  },
  setup (props) {
    const isColorChange = computed(() => {
      let className = ''
      if (props.value >= 5) {
        className = 'quantity__btn--yellow'
      } else if (props.value >= 3) {
        className = 'quantity__btn--red'
      }
      return className
    })
    const inputVal = ref(props.value)
    function plus () {
      inputVal.value += 1
    }
    function minus () {
      if (inputVal.value > 0) {
        inputVal.value -= 1
      }
    }
    return {
      isColorChange,
      inputVal,
      plus,
      minus
    }
  }
}
</script>
